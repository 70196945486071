var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"userForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.firstname')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1",attrs:{"maxlength":"30","type":"text"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.lastname')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1",attrs:{"maxlength":"30","type":"text"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.username')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","maxlength":"40","error":errors[0]},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.operator_number')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"username","rules":"numeric|between:0,9999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0 flex-grow-1",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"maxlength":"4","type":"text","error":errors[0]},model:{value:(_vm.user.operator_number),callback:function ($$v) {_vm.$set(_vm.user, "operator_number", $$v)},expression:"user.operator_number"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.salary')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"username","rules":"double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0 flex-grow-1",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"maxlength":"10","type":"text","error":errors[0]},model:{value:(_vm.user.salary),callback:function ($$v) {_vm.$set(_vm.user, "salary", $$v)},expression:"user.salary"}})]}}])})],1)],2),(_vm.$route.name !== 'users.profile')?_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.active')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"flex-grow-1 mb-0"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.choose')},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}},_vm._l(([
                { value: true, label: _vm.$t('common.yes')},
                { value: false, label: _vm.$t('common.no')} ]),function(option){return _c('el-option',{key:option.value,staticClass:"select-default text-uppercase",attrs:{"value":option.value,"label":option.label}})}),1)],1)],1)],2):_vm._e(),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.profile')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.profile')},model:{value:(_vm.user.profile),callback:function ($$v) {_vm.$set(_vm.user, "profile", $$v)},expression:"user.profile"}},_vm._l((_vm.profileOptions),function(profile,index){return _c('el-option',{key:("user-profile-" + index),staticClass:"select-default text-uppercase",attrs:{"value":profile,"label":profile}})}),1)],1)],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.call_link')}},[_c('template',{slot:"value"},[_c('base-text-area',{staticClass:"flex-grow-1",attrs:{"rows":3},model:{value:(_vm.user.call_link),callback:function ($$v) {_vm.$set(_vm.user, "call_link", $$v)},expression:"user.call_link"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }